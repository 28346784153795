<template>
  <aside class="flex flex-col items-start mb-4">
    <p class="title__search mb-2">{{ title }}</p>
    <label for="" class="search w-full items-center rounded-md mb-0 flex">
      <img class="w-4" src="@/assets/img/lupa.png" alt="Lupa" />
      <input
        v-model="typedValue"
        class="search__input p-2 bg-transparent border-none focus:outline-none w-full focus:ring-0"
        type="text"
        placeholder="Pesquisar"
      />
      <button
        class="search__button cursor-auto bg-transparent border-none"
        @click="() => (typedValue = '')"
      >
        <CloseIcon class="w-4" />
      </button>
    </label>
  </aside>
</template>

<script>
import CloseIcon from "@/assets/img/close.svg";

export default {
  name: "MainSearchBarPanel",
  components: {
    CloseIcon,
  },
  props: ["title"],
  data() {
    return {
      typedValue: "",
      isExpandSearch: false,
    };
  },
  methods: {
    expandSearch() {
      this.isExpandSearch = !this.isExpandSearch;
      this.$nextTick(() => this.$refs.searchInput.focus());
      this.$emit("searchBarIsExpanded", true);
    },
    retractSearch() {
      this.isExpandSearch = !this.isExpandSearch;
      this.typedValue = "";
      this.$emit("searchBarIsExpanded", false);
    },
  },
  watch: {
    typedValue(value) {
      this.$emit("input", value);
      
    }
  }
};
</script>

<style lang="scss" scoped>
.title__search {
  color: #5b6873;
}

.search {
  background-color: #5b687318;
  padding-right: 0.75rem;
  padding-left: 0.75rem;

  @media screen and (max-width: 767px) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  &__input-background {
    background-color: #5b687318 !important;
  }

  &__button svg {
    visibility: hidden;
    opacity: 0;
    fill: #5b6873dc;
  }

  &__input:focus ~ &__button {
    cursor: pointer !important;

    svg {
      transition: opacity 0.5s ease-in-out;
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
