<template>
  <div
    class="w-full flex flex-col-reverse md:flex-row items-start justify-center md:justify-between mt-1"
  >
    <span
      class="w-full flex items-center justify-center md:justify-start text-gray-500"
    >
      Total de {{ text }}: {{ totalItems }}
    </span>
    <div class="w-full flex items-center justify-center md:justify-end">
      <b-pagination-nav
        v-model="currentPage"
        :number-of-pages="totalPages"
        base-url="?page="
        first-number
        last-number
        use-router
      >
        <template #prev-text>
          <span class="w-4 h-4 flex border-0 hover:bg-transparent">
            <ArrowLeft />
          </span>
        </template>
        <template #next-text>
          <span class="bg-transparent w-4 h-4 flex">
            <ArrowRight />
          </span>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script type="module">
import { BPaginationNav } from "bootstrap-vue";
import ArrowRight from "@/assets/img/arrow-right.svg";
import ArrowLeft from "@/assets/img/arrow-left.svg";
export default {
  name: "Pagination",
  components: {
    BPaginationNav,
    ArrowRight,
    ArrowLeft,
  },
  props: {
    totalItems: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 1,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
        currentPage:1
      }
  },
  methods: {
    query (page) {
      return {
        ...this.$route.query,
        _page: page,
      };
    },
  },
  computed: {
    totalPages () {
      const total = this.totalItems / this.itemsPerPage;
      return total !== Infinity ? Math.ceil(total) : 0;
    },
  },
  watch: {
    currentPage (pagina) {
       return this.$emit("input",pagina)
    }
  },
};
</script>

<style>
.page-item .page-link {
  color: #333;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.3rem 0.55rem!important;
}
.page-item .page-link:hover {
  background-color: transparent;
}
.page-item .page-link:focus {
  box-shadow: 0 0 0 0;
  border-color: #cf0209;
  outline: 0;
}
.page-item.active .page-link {
  color: #fff;
  background-color: #cf0209;
  border-color: #cf0209;
}
</style>
