<template>
    <section>
        <search-bar-register
        class="mt-2 mb-2 sm:mb-3"
        title="Pesquisa de Grupo de Produtos"
        @input="filter = $event"
        />
        <b-table
        responsive="sm"
        :fields="fields"
        primary-key="Grupo de Produtos"
        :items="items"
        :filter="filter"
        :perPage="itemsPerPage"
        show-empty
        empty-text="Não existem Grupos de Produtos cadastrados"
        :empty-filtered-text="`Grupo de Produtos não encontrado`"
        >
            <template #cell(Grupo_de_Produtos)="data">
                {{ Indice + (data.index + 1) }}
            </template>
            <template #cell(ações)="data">
                <div class="acoes d-flex justify-center gap-5">
                    <div>
                        <button @click="RedirectUrl(`view/${data.item.id}`)">
                            <EyeIcon class="w-5 mr-2 icon__actions" />
                        </button>
                    </div>
                    <div>
                        <button @click="RedirectUrl(`edit/${data.item.id}`)">
                            <EditIcon class="w-4 mr-2 icon__actions" />
                        </button>
                    </div>
                    <div>
                        <button @click="deletarEnsaio(data.item.id)">
                            <DeleteIcon class="w-5 mr-2 icon__actions" />
                        </button>
                    </div>
                </div>
            </template>
        </b-table>
        <footer class="mt-2 flex items-center">
            <Pagination
                :totalItems="totalItems"
                :itemsPerPage="itemsPerPage"
                @input="ListaProdutos"
                text="Grupo de Produtos"
            />
        </footer>

        <Modal
        v-if="modalIsOpen"
        :modalIsOpen="modalIsOpen"
        :closeModal="closeModal"
        :buttonOptions="true"
        :confirmarDelete="confirmarDelete"
        :DeleteId="deletar"
        :modalText="$t('commons.modalTextDelete')"
        />
        <Modal
        v-if="modalDeleted"
        :modalIsOpen="modalDeleted"
        :closeModal="closeModal"
        :modalText="$t('commons.modalTextDeleted')"
        />
    </section>
</template>

<script>
import { BTable } from "bootstrap-vue";

import SearchBarRegister from "@core/components/register/SearchBarRegister.vue";
import Pagination from "@core/components/commons/pagination/Pagination.vue";
import Modal from "@core/components/commons/modal/Modal.vue";

import EyeIcon from "@/assets/img/eye.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
    name: 'ListProducts',
    components: {
        BTable,
        SearchBarRegister,
        Pagination,
        Modal,
        EyeIcon,
        EditIcon,
        DeleteIcon,
    },
    data() {
        return {
            modalIsOpen: false,
            modalDeleted:false,
            Indice: 0,
            totalItems: 0,
            itemsPerPage: 2,
            delete: null,
            filter: null,
            fields: [
                "Grupo_de_Produtos",
                { key: "descricao", label: "Descrição",sortable: true },
                { key: "ações" }
            ],
            items: [],
         }
    },
    created() {
        this.ListaProdutos();
    },
    methods: {
        closeModal(event) {
            if (event.target === event.currentTarget) {
                this.modalIsOpen = false;
                this.modalDeleted = false;
                window.scrollTo({
                top: 0,
                behavior: "smooth",
                });
            }
        },
        ListaProdutos(page = 1) {
            this.$http({
                url: `/grupoProduto/obter-grid/${page}`,
                method: "Get",
            }).then((response) => {
                this.items = response.data.itens;
                this.totalItems = response.data.total;
                this.itemsPerPage = response.data.itensPorPagina;
                var IndiceFinal = page * response.data.itensPorPagina;
                this.Indice = IndiceFinal - response.data.itensPorPagina;
            });
        },
        RedirectUrl(value) {
            this.$router.push(`/groupProducts/${value}`);
        },
        deletarEnsaio(id) {
            this.modalIsOpen = true;
            this.deletar = id;
        },
        confirmarDelete(id) {
            this.$http({
                url: `/grupoProduto/remover/${id}`,
                method: "Post",
            }).then(() => {
                this.modalIsOpen = false;
                this.modalDeleted = true;
                setTimeout(() => this.$router.go(), 2000);
            });
        },
    },
}
</script>

<style lang="scss">
    .acoes {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
    }
</style>