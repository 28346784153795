import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListProducts from "@/components/groupProducts/ListProducts";

export default {
  components: {
    HeaderRegister,
    ListProducts,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};